// src/App.js

import React from "react";
import { AuthProvider, useAuth } from "./context/AuthContext";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from "./components/login";
import CRM from "./components/crm";
import Roadmap from "./components/roadmap";
import Navbar from "./components/navbar";

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          
          {/* Routes protégées */}
          <Route
            path="/*"
            element={
              <PrivateRoute>
                <LayoutProtected />
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

// Composant pour gérer le layout des routes protégées
const LayoutProtected = () => {
  return (
    <>
      <Navbar /> {/* Afficher la Navbar */}
      <Routes>
        <Route path="/crm" element={<CRM />} />
        <Route path="/roadmap" element={<Roadmap />} />
        {/* Ajouter d'autres routes protégées ici */}
        <Route path="*" element={<Navigate to="/crm" />} />
      </Routes>
    </>
  );
};

// Composant pour protéger les routes privées
const PrivateRoute = ({ children }) => {
  const { currentUser } = useAuth();

  return currentUser ? children : <Navigate to="/login" />;
};

export default App;