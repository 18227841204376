// src/firebase/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Votre configuration Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBU7qPO0Jij9NCYCpQMlOlufurmhvU4mcs",
  authDomain: "admin-gurudeals-43c57.firebaseapp.com",
  projectId: "admin-gurudeals-43c57",
  storageBucket: "admin-gurudeals-43c57.appspot.com",
  messagingSenderId: "756642266802",
  appId: "1:756642266802:web:89510732eab8b220a193ab"
};


// Initialiser Firebase
const app = initializeApp(firebaseConfig);

// Initialiser Firestore
const db = getFirestore(app);

// Initialiser Authentication
const auth = getAuth(app);

export { db, auth };