// src/components/CRM.js

import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  Typography,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Select,
  IconButton,
  Box,
} from "@mui/material";
import { Add, ArrowBack, ArrowForward } from "@mui/icons-material";
import {
  recupererCRM,
  supprimerCRM,
  mettreAJourCRM,
  ajouterEntrepriseCRM,
} from "../firebase/firestoreFunctions";

const CRM = () => {
  const [columns, setColumns] = useState({
    Prospect: [],
    Demande: [],
    Partenaire: [],
  });
  const [openView, setOpenView] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editedData, setEditedData] = useState({
    name: "",
    link: "",
    contact: "",
    notes: "",
  });
  const [newCompany, setNewCompany] = useState({
    name: "",
    status: "Prospect",
    notes: "",
    contact: "",
    link: "",
    assignéÀ: "",
  });

  const statusCategories = ["Prospect", "Demande", "Partenaire"];
  const assignéOptions = ["Pierre", "Axel"]; // Ajoutez d'autres noms si nécessaire

  useEffect(() => {
    const fetchData = async () => {
      const data = await recupererCRM();
      const categorized = {
        Prospect: [],
        Demande: [],
        Partenaire: [],
      };
      data.forEach((item) => {
        if (categorized[item.status]) {
          categorized[item.status].push(item);
        } else {
          console.warn(`Statut inconnu pour l'entreprise: ${item.name}`, item);
        }
      });
      setColumns(categorized);
    };
    fetchData();
  }, []);

  const handleMoveCompany = async (company, direction) => {
    let newStatus;
    if (company.status === "Prospect" && direction === "forward") {
      newStatus = "Demande";
    } else if (company.status === "Demande") {
      if (direction === "backward") {
        newStatus = "Prospect";
      } else if (direction === "forward") {
        newStatus = "Partenaire";
      }
    } else if (company.status === "Partenaire" && direction === "backward") {
      newStatus = "Demande";
    }

    if (!newStatus) {
      return; // Aucun déplacement possible
    }

    try {
      // Mettre à jour Firestore
      await mettreAJourCRM(company.id, { status: newStatus });

      // Mettre à jour l'état local
      setColumns((prev) => {
        const newColumns = { ...prev };
        // Retirer de l'ancienne colonne
        newColumns[company.status] = newColumns[company.status].filter(
          (item) => item.id !== company.id
        );
        // Ajouter à la nouvelle colonne
        newColumns[newStatus] = [...newColumns[newStatus], { ...company, status: newStatus }];
        return newColumns;
      });
    } catch (error) {
      console.error("Erreur lors du déplacement de l'entreprise:", error);
      alert("Erreur lors du déplacement de l'entreprise. Veuillez réessayer.");
    }
  };

  const handleCardClick = (item) => {
    setCurrentItem(item);
    setEditedData({
      name: item.name,
      link: item.link,
      contact: item.contact,
      notes: item.notes,
    });
    setEditMode(false);
    setOpenView(true);
  };

  const handleCloseView = () => {
    setOpenView(false);
    setCurrentItem(null);
    setEditMode(false);
  };

  const handleDelete = async () => {
    if (currentItem) {
      try {
        await supprimerCRM(currentItem.id);
        setColumns((prev) => {
          const newColumns = { ...prev };
          newColumns[currentItem.status] = newColumns[currentItem.status].filter(
            (item) => item.id !== currentItem.id
          );
          return newColumns;
        });
        handleCloseView();
      } catch (error) {
        console.error("Erreur lors de la suppression de l'entreprise:", error);
        alert("Erreur lors de la suppression de l'entreprise. Veuillez réessayer.");
      }
    }
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditedData((prev) => ({ ...prev, [name]: value }));
  };

  const handleEditSave = async () => {
    if (currentItem) {
      try {
        await mettreAJourCRM(currentItem.id, {
          name: editedData.name,
          link: editedData.link,
          contact: editedData.contact,
          notes: editedData.notes,
        });
        setColumns((prev) => {
          const newColumns = { ...prev };
          newColumns[currentItem.status] = newColumns[currentItem.status].map((item) =>
            item.id === currentItem.id ? { ...item, ...editedData } : item
          );
          return newColumns;
        });
        handleCloseView();
      } catch (error) {
        console.error("Erreur lors de la mise à jour de l'entreprise:", error);
        alert("Erreur lors de la mise à jour de l'entreprise. Veuillez réessayer.");
      }
    }
  };

  const handleOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
    setNewCompany({
      name: "",
      status: "Prospect",
      notes: "",
      contact: "",
      link: "",
      assignéÀ: "",
    });
  };

  const handleAddChange = (e) => {
    const { name, value } = e.target;
    setNewCompany((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddSave = async () => {
    const { name, status, notes, contact, link, assignéÀ } = newCompany;
    if (name.trim() === "") {
      alert("Le nom de l'entreprise est requis.");
      return;
    }
    if (assignéÀ.trim() === "") {
      alert("Le champ 'Assigné à' est requis.");
      return;
    }
    try {
      const newEntry = {
        name,
        status,
        notes,
        contact,
        link,
        assignéÀ,
      };
      const addedItem = await ajouterEntrepriseCRM(newEntry);
      if (addedItem && addedItem.id) {
        setColumns((prev) => {
          const newCol = Array.from(prev[status]);
          newCol.push(addedItem);
          return {
            ...prev,
            [status]: newCol,
          };
        });
      }
      handleCloseAdd();
    } catch (error) {
      console.error("Erreur lors de l'ajout de l'entreprise:", error);
      alert("Erreur lors de l'ajout de l'entreprise. Veuillez réessayer.");
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <Typography variant="h4" align="center" gutterBottom>
        CRM - Kanban Board
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<Add />}
        onClick={handleOpenAdd}
        style={{ marginBottom: "20px" }}
      >
        Ajouter une Entreprise
      </Button>
      <Grid container spacing={2} justifyContent="center">
        {statusCategories.map((status, index) => (
          <Grid item xs={12} md={4} key={status}>
            <Typography variant="h6" align="center" gutterBottom>
              {status}
            </Typography>
            <Box
              sx={{
                background: "#fafafa",
                padding: 2,
                minHeight: 500,
                borderRadius: 2,
                boxShadow: 1,
              }}
            >
              {columns[status].map((item) => (
                <Card
                  key={item.id}
                  sx={{
                    marginBottom: 2,
                    position: "relative",
                    "&:hover .move-buttons": {
                      display: "flex",
                    },
                    cursor: "pointer",
                  }}
                  onClick={() => handleCardClick(item)}
                >
                  <CardContent
                    sx={{
                      paddingLeft: "40px", // Ajout de padding à gauche
                      paddingRight: "40px", // Ajout de padding à droite
                      position: "relative",
                    }}
                  >
                    <Typography variant="subtitle1">{item.name}</Typography>
                  </CardContent>
                  {/* Boutons de déplacement */}
                  <Box
                    className="move-buttons"
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: 0,
                      right: 0,
                      display: "none",
                      justifyContent:
                        status === "Prospect"
                          ? "flex-end" // Aligné à droite pour Prospect
                          : status === "Partenaire"
                          ? "flex-start" // Aligné à gauche pour Partenaire
                          : "space-between", // Both for Demande
                      transform: "translateY(-50%)",
                      pointerEvents: "none", // Empêche les flèches de déclencher des clics sur la carte
                    }}
                  >
                    {/* Bouton arrière */}
                    {status !== "Prospect" && (
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation(); // Empêche le déclenchement du onClick de la carte
                          handleMoveCompany(item, "backward");
                        }}
                        sx={{
                          backgroundColor: "rgba(255, 255, 255, 0.7)",
                          "&:hover": {
                            backgroundColor: "rgba(255, 255, 255, 1)",
                          },
                          pointerEvents: "auto", // Réactive les événements de pointeur
                        }}
                      >
                        <ArrowBack />
                      </IconButton>
                    )}
                    {/* Bouton avant */}
                    {status !== "Partenaire" && (
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation(); // Empêche le déclenchement du onClick de la carte
                          handleMoveCompany(item, "forward");
                        }}
                        sx={{
                          backgroundColor: "rgba(255, 255, 255, 0.7)",
                          "&:hover": {
                            backgroundColor: "rgba(255, 255, 255, 1)",
                          },
                          pointerEvents: "auto", // Réactive les événements de pointeur
                        }}
                      >
                        <ArrowForward />
                      </IconButton>
                    )}
                  </Box>
                </Card>
              ))}
            </Box>
          </Grid>
        ))}
      </Grid>

      {/* Dialog for Viewing and Editing CRM Item */}
      <Dialog open={openView} onClose={handleCloseView} maxWidth="sm" fullWidth>
        <DialogTitle>
          {editMode ? "Modifier l'Entreprise" : "Détails de l'Entreprise"}
        </DialogTitle>
        <DialogContent>
          {currentItem && (
            <>
              <DialogContentText>
                {editMode
                  ? "Modifiez les informations de l'entreprise."
                  : "Informations détaillées de l'entreprise."}
              </DialogContentText>
              <TextField
                margin="dense"
                label="Nom"
                name="name"
                fullWidth
                variant="standard"
                value={editedData.name}
                onChange={handleEditChange}
                disabled={!editMode}
              />
              <TextField
                margin="dense"
                label="Lien"
                name="link"
                fullWidth
                variant="standard"
                value={editedData.link}
                onChange={handleEditChange}
                disabled={!editMode}
              />
              <TextField
                margin="dense"
                label="Contact"
                name="contact"
                fullWidth
                variant="standard"
                value={editedData.contact}
                onChange={handleEditChange}
                disabled={!editMode}
              />
              <TextField
                margin="dense"
                label="Notes"
                name="notes"
                fullWidth
                multiline
                rows={4}
                variant="standard"
                value={editedData.notes}
                onChange={handleEditChange}
                disabled={!editMode}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          {editMode ? (
            <>
              <Button onClick={handleCloseView}>Annuler</Button>
              <Button onClick={handleEditSave} color="primary">
                Enregistrer
              </Button>
            </>
          ) : (
            <>
              <Button onClick={handleCloseView}>Fermer</Button>
              <Button onClick={handleEdit} color="primary">
                Modifier
              </Button>
              <Button onClick={handleDelete} color="secondary">
                Supprimer
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      {/* Dialog for Adding New CRM Item */}
      <Dialog open={openAdd} onClose={handleCloseAdd} maxWidth="sm" fullWidth>
        <DialogTitle>Ajouter une Nouvelle Entreprise</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Remplissez les informations de la nouvelle entreprise.
          </DialogContentText>
          <TextField
            margin="dense"
            label="Nom"
            name="name"
            fullWidth
            variant="standard"
            value={newCompany.name}
            onChange={handleAddChange}
            required
          />
          <TextField
            margin="dense"
            label="Lien"
            name="link"
            fullWidth
            variant="standard"
            value={newCompany.link}
            onChange={handleAddChange}
          />
          <TextField
            margin="dense"
            label="Contact"
            name="contact"
            fullWidth
            variant="standard"
            value={newCompany.contact}
            onChange={handleAddChange}
          />
          <TextField
            margin="dense"
            label="Notes"
            name="notes"
            fullWidth
            multiline
            rows={4}
            variant="standard"
            value={newCompany.notes}
            onChange={handleAddChange}
          />
          <Select
            margin="dense"
            label="Statut"
            name="status"
            fullWidth
            variant="standard"
            value={newCompany.status}
            onChange={handleAddChange}
            displayEmpty
          >
            <MenuItem value="" disabled>
              Sélectionner le statut
            </MenuItem>
            {statusCategories.map((status) => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </Select>
          <Select
            margin="dense"
            label="Assigné à"
            name="assignéÀ"
            fullWidth
            variant="standard"
            value={newCompany.assignéÀ}
            onChange={handleAddChange}
            displayEmpty
            required
          >
            <MenuItem value="" disabled>
              Sélectionner
            </MenuItem>
            {assignéOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAdd}>Annuler</Button>
          <Button onClick={handleAddSave} color="primary">
            Ajouter
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CRM;