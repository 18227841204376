// src/components/Navbar.js

import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useAuth } from "../context/AuthContext";
import { signOut } from "firebase/auth";
import { auth } from "../firebase/firebase";
import { useNavigate, Link } from "react-router-dom";

const Navbar = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {
      console.error("Erreur lors de la déconnexion:", error);
      alert("Erreur lors de la déconnexion. Veuillez réessayer.");
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography
        variant="h6"
        sx={{
          my: 2,
          color: "#1e1235",
          textDecoration: "none",
        }}
      >
        <Link to="/crm" style={{ textDecoration: "none", color: "inherit" }}>
          Admin Gurudeals
        </Link>
      </Typography>
      <List>
        <ListItem button component={Link} to="/crm">
          <ListItemText primary="CRM" />
        </ListItem>
        <ListItem button component={Link} to="/roadmap">
          <ListItemText primary="Roadmap" />
        </ListItem>
        <ListItem button onClick={handleLogout}>
          <ListItemText primary="Déconnexion" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#1e1235", // Couleur de fond personnalisée
        }}
      >
        <Toolbar>
          {/* Titre */}
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >
            <Link to="/crm" style={{ textDecoration: "none", color: "inherit" }}>
              Admin Gurudeals
            </Link>
          </Typography>

          {/* Bouton Menu pour Mobile */}
          {currentUser && (
            <Box sx={{ display: { xs: "block", sm: "none" } }}>
              <IconButton
                color="inherit"
                aria-label="ouvrir le menu"
                edge="start"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          )}

          {/* Liens de Navigation pour Desktop */}
          {currentUser && (
            <Box sx={{ display: { xs: "none", sm: "flex" }, gap: "10px" }}>
              <Button
                color="inherit"
                component={Link}
                to="/crm"
                sx={{
                  "&:hover": {
                    backgroundColor: "#ff7606", // Couleur de survol
                    color: "#fff",
                  },
                }}
              >
                CRM
              </Button>
              <Button
                color="inherit"
                component={Link}
                to="/roadmap"
                sx={{
                  "&:hover": {
                    backgroundColor: "#ff7606", // Couleur de survol
                    color: "#fff",
                  },
                }}
              >
                Roadmap
              </Button>
              {/* Bouton de Déconnexion */}
              <Button
                color="inherit"
                onClick={handleLogout}
                sx={{
                  "&:hover": {
                    backgroundColor: "#ff7606", // Couleur de survol
                    color: "#fff",
                  },
                }}
              >
                Déconnexion
              </Button>
            </Box>
          )}
        </Toolbar>
      </AppBar>

      {/* Drawer pour Mobile */}
      <Drawer
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Meilleure performance sur mobile
        }}
      >
        {drawer}
      </Drawer>
    </>
  );
};

export default Navbar;