// src/components/Roadmap.js
import React, { useState, useEffect, useMemo } from "react";
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  TableSortLabel, IconButton, Select, MenuItem, TextField, Button, Tooltip,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  FormControl, InputLabel, Box
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { recupererRoadmap, ajouterElementRoadmap, supprimerRoadmap, mettreAJourRoadmap } from "../firebase/firestoreFunctions";
import "./roadmap.css"; // Pour les styles supplémentaires si nécessaire

const Roadmap = () => {
  const [roadmap, setRoadmap] = useState([]);
  const [nouvelElement, setNouvelElement] = useState({
    function: "",
    date: "",
    state: "",
    priority: "",
    comment: "",
    owner: ""
  });
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('priority');
  const [open, setOpen] = useState(false);
  const [currentEdit, setCurrentEdit] = useState(null);
  const [editValues, setEditValues] = useState({
    function: "",
    date: "",
    state: "",
    priority: "",
    comment: "",
    owner: ""
  });
  
  // États pour les filtres
  const [filterState, setFilterState] = useState('');
  const [filterPriority, setFilterPriority] = useState('');
  const [filterOwner, setFilterOwner] = useState('');

  useEffect(() => {
    const fetchRoadmap = async () => {
      const data = await recupererRoadmap();
      console.log("Roadmap Data:", data); // Pour déboguer
      setRoadmap(data);
    };
    fetchRoadmap();
  }, []);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedRoadmap = useMemo(() => {
    return roadmap.slice().sort((a, b) => {
      if (orderBy === 'date') {
        return order === 'asc' ? a.date.seconds - b.date.seconds : b.date.seconds - a.date.seconds;
      } else {
        if (a[orderBy] < b[orderBy]) return order === 'asc' ? -1 : 1;
        if (a[orderBy] > b[orderBy]) return order === 'asc' ? 1 : -1;
        return 0;
      }
    });
  }, [roadmap, order, orderBy]);

  // Fonction de filtrage
  const filteredRoadmap = useMemo(() => {
    return sortedRoadmap.filter(item => {
      const matchesState = filterState ? item.state === filterState : true;
      const matchesPriority = filterPriority ? item.priority === filterPriority : true;
      const matchesOwner = filterOwner ? item.owner === filterOwner : true;
      return matchesState && matchesPriority && matchesOwner;
    });
  }, [sortedRoadmap, filterState, filterPriority, filterOwner]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNouvelElement({ ...nouvelElement, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await ajouterElementRoadmap({
      ...nouvelElement,
      date: new Date(nouvelElement.date)
    });
    setNouvelElement({
      function: "",
      date: "",
      state: "",
      priority: "",
      comment: "",
      owner: ""
    });
    const data = await recupererRoadmap();
    setRoadmap(data);
  };

  const handleDelete = async (id) => {
    await supprimerRoadmap(id);
    const data = await recupererRoadmap();
    setRoadmap(data);
  };

  const handleStateChange = async (id, newState) => {
    await mettreAJourRoadmap(id, { state: newState });
    const data = await recupererRoadmap();
    setRoadmap(data);
  };

  const getStateColor = (state) => {
    switch(state) {
      case 'En cours':
        return '#ff7606'; // Orange
      case 'Terminé':
        return '#ffbd59'; // Vert
      case 'À venir':
        return '#1800ff'; // Bleu
      default:
        return '#6c757d'; // Gris
    }
  };

  const handleEditClick = (item) => {
    setCurrentEdit(item);
    setEditValues({
      function: item.function,
      date: new Date(item.date.seconds * 1000).toISOString().split('T')[0], // Format YYYY-MM-DD
      state: item.state,
      priority: item.priority,
      comment: item.comment,
      owner: item.owner
    });
    setOpen(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditValues({ ...editValues, [name]: value });
  };

  const handleEditSave = async () => {
    if (currentEdit) {
      await mettreAJourRoadmap(currentEdit.id, {
        function: editValues.function,
        date: new Date(editValues.date),
        state: editValues.state,
        priority: editValues.priority,
        comment: editValues.comment,
        owner: editValues.owner
      });
      const data = await recupererRoadmap();
      setRoadmap(data);
      setOpen(false);
      setCurrentEdit(null);
    }
  };

  const handleEditCancel = () => {
    setOpen(false);
    setCurrentEdit(null);
  };

  // Fonction pour réinitialiser les filtres
  const resetFilters = () => {
    setFilterState('');
    setFilterPriority('');
    setFilterOwner('');
  };

  return (
    <div className="roadmap-container">
      <h2>Roadmap Produit</h2>
      
      {/* Formulaire d'ajout d'un nouvel élément */}
      <form onSubmit={handleSubmit} style={{ marginBottom: '20px', display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
        <TextField
          type="text"
          name="function"
          label="Fonction"
          value={nouvelElement.function}
          onChange={handleChange}
          required
          style={{ minWidth: '200px' }}
        />
        <TextField
          type="date"
          name="date"
          label="Date"
          InputLabelProps={{ shrink: true }}
          value={nouvelElement.date}
          onChange={handleChange}
          required
          style={{ minWidth: '150px' }}
        />
        <FormControl style={{ minWidth: '150px' }}>
          <InputLabel>État</InputLabel>
          <Select
            name="state"
            value={nouvelElement.state}
            onChange={handleChange}
            required
            label="État"
          >
            <MenuItem value="" disabled>Sélectionner l'état</MenuItem>
            <MenuItem value="En cours">En cours</MenuItem>
            <MenuItem value="Terminé">Terminé</MenuItem>
            <MenuItem value="À venir">À venir</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{ minWidth: '150px' }}>
          <InputLabel>Priorité</InputLabel>
          <Select
            name="priority"
            value={nouvelElement.priority}
            onChange={handleChange}
            required
            label="Priorité"
          >
            <MenuItem value="" disabled>Sélectionner la priorité</MenuItem>
            <MenuItem value="Haute">Haute</MenuItem>
            <MenuItem value="Moyenne">Moyenne</MenuItem>
            <MenuItem value="Basse">Basse</MenuItem>
          </Select>
        </FormControl>
        <TextField
          type="text"
          name="comment"
          label="Description"
          value={nouvelElement.comment}
          onChange={handleChange}
          style={{ minWidth: '200px' }}
        />
        <FormControl style={{ minWidth: '150px' }}>
          <InputLabel>Assigné à</InputLabel>
          <Select
            name="owner"
            value={nouvelElement.owner}
            onChange={handleChange}
            required
            label="Assigné à"
          >
            <MenuItem value="" disabled>Sélectionner le propriétaire</MenuItem>
            <MenuItem value="Pierre">Pierre</MenuItem>
            <MenuItem value="Axel">Axel</MenuItem>
          </Select>
        </FormControl>
        <Button variant="contained" color="primary" type="submit">Ajouter</Button>
      </form>
      
      {/* Section des filtres */}
      <Box sx={{ marginBottom: '20px', display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
      <FormControl style={{ minWidth: '150px' }}>
        <InputLabel id="filter-state-label"></InputLabel>
        <Select
            labelId="filter-state-label" // Associer le label
            id="filter-state" // Ajouter un ID unique
            value={filterState}
            onChange={(e) => setFilterState(e.target.value)}
            displayEmpty
            label="Filtrer par État" // Assurer que le label est bien passé
        >
            <MenuItem value="">
            <em>Tous les états</em>
            </MenuItem>
            <MenuItem value="En cours">En cours</MenuItem>
            <MenuItem value="Terminé">Terminé</MenuItem>
            <MenuItem value="À venir">À venir</MenuItem>
        </Select>
        </FormControl>
        <FormControl style={{ minWidth: '150px' }}>
        <InputLabel id="filter-priority-label"></InputLabel>
        <Select
            labelId="filter-priority-label"
            id="filter-priority"
            value={filterPriority}
            onChange={(e) => setFilterPriority(e.target.value)}
            displayEmpty
            label="Filtrer par Priorité"
        >
            <MenuItem value="">
            <em>Toutes les priorités</em>
            </MenuItem>
            <MenuItem value="Haute">Haute</MenuItem>
            <MenuItem value="Moyenne">Moyenne</MenuItem>
            <MenuItem value="Basse">Basse</MenuItem>
        </Select>
        </FormControl>
        <FormControl style={{ minWidth: '150px' }}>
        <InputLabel id="filter-owner-label"></InputLabel>
        <Select
            labelId="filter-owner-label"
            id="filter-owner"
            value={filterOwner}
            onChange={(e) => setFilterOwner(e.target.value)}
            displayEmpty
            label="Filtrer par Propriétaire"
        >
            <MenuItem value="">
            <em>Tous les propriétaires</em>
            </MenuItem>
            <MenuItem value="Pierre">Pierre</MenuItem>
            <MenuItem value="Axel">Axel</MenuItem>
        </Select>
        </FormControl>
        <Button variant="outlined" onClick={resetFilters}>Réinitialiser les filtres</Button>
      </Box>
      
      {/* Tableau de la roadmap */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'state'}
                  direction={orderBy === 'state' ? order : 'asc'}
                  onClick={() => handleRequestSort('state')}
                >
                  État
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'function'}
                  direction={orderBy === 'function' ? order : 'asc'}
                  onClick={() => handleRequestSort('function')}
                >
                  Fonction
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'date'}
                  direction={orderBy === 'date' ? order : 'asc'}
                  onClick={() => handleRequestSort('date')}
                >
                  Date
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'priority'}
                  direction={orderBy === 'priority' ? order : 'asc'}
                  onClick={() => handleRequestSort('priority')}
                >
                  Priorité
                </TableSortLabel>
              </TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Assigné à</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRoadmap.map((item) => (
              <TableRow key={item.id} hover>
                <TableCell>
                  <Select
                    value={item.state}
                    onChange={(e) => handleStateChange(item.id, e.target.value)}
                    style={{ minWidth: '150px', backgroundColor: getStateColor(item.state), color: '#fff' }}
                    sx={{
                      '& .MuiSelect-select': {
                        padding: '10px'
                      }
                    }}
                  >
                    <MenuItem value="En cours">En cours</MenuItem>
                    <MenuItem value="Terminé">Terminé</MenuItem>
                    <MenuItem value="À venir">À venir</MenuItem>
                  </Select>
                </TableCell>
                <TableCell>{item.function}</TableCell>
                <TableCell>{new Date(item.date.seconds * 1000).toLocaleDateString()}</TableCell>
                <TableCell>{item.priority}</TableCell>
                <TableCell>{item.comment}</TableCell>
                <TableCell>{item.owner}</TableCell>
                <TableCell>
                  <Tooltip title="Modifier">
                    <IconButton color="primary" onClick={() => handleEditClick(item)}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Supprimer">
                    <IconButton color="secondary" onClick={() => handleDelete(item.id)}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
            {filteredRoadmap.length === 0 && (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  Aucune donnée correspondante.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog pour l'édition */}
      <Dialog open={open} onClose={handleEditCancel}>
        <DialogTitle>Modifier l'Élément</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Modifiez les détails de l'élément.
          </DialogContentText>
          <form>
            <TextField
              margin="dense"
              name="function"
              label="Fonction"
              type="text"
              fullWidth
              variant="standard"
              value={editValues.function}
              onChange={handleEditChange}
              required
            />
            <TextField
              margin="dense"
              name="date"
              label="Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="standard"
              value={editValues.date}
              onChange={handleEditChange}
              required
            />
            <FormControl fullWidth margin="dense" variant="standard">
              <InputLabel>État</InputLabel>
              <Select
                name="state"
                value={editValues.state}
                onChange={handleEditChange}
                required
              >
                <MenuItem value="En cours">En cours</MenuItem>
                <MenuItem value="Terminé">Terminé</MenuItem>
                <MenuItem value="À venir">À venir</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth margin="dense" variant="standard">
              <InputLabel>Priorité</InputLabel>
              <Select
                name="priority"
                value={editValues.priority}
                onChange={handleEditChange}
                required
              >
                <MenuItem value="Haute">Haute</MenuItem>
                <MenuItem value="Moyenne">Moyenne</MenuItem>
                <MenuItem value="Basse">Basse</MenuItem>
              </Select>
            </FormControl>
            <TextField
              margin="dense"
              name="comment"
              label="Description"
              type="text"
              fullWidth
              variant="standard"
              value={editValues.comment}
              onChange={handleEditChange}
            />
            <FormControl fullWidth margin="dense" variant="standard">
              <InputLabel>Assigné à</InputLabel>
              <Select
                name="owner"
                value={editValues.owner}
                onChange={handleEditChange}
                required
              >
                <MenuItem value="Pierre">Pierre</MenuItem>
                <MenuItem value="Axel">Axel</MenuItem>
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditCancel}>Annuler</Button>
          <Button onClick={handleEditSave} variant="contained" color="primary">Enregistrer</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Roadmap;