// src/firebase/firestoreFunctions.js
import { db } from "./firebase";
import { collection, addDoc, getDocs, updateDoc, deleteDoc, doc } from "firebase/firestore";

// Ajouter un élément à la collection 'roadmap'
export const ajouterElementRoadmap = async (element) => {
  try {
    const docRef = await addDoc(collection(db, "roadmap"), element);
    console.log("Document roadmap ajouté avec ID :", docRef.id);
  } catch (e) {
    console.error("Erreur lors de l'ajout du document roadmap :", e);
  }
};

// Récupérer tous les éléments de 'roadmap'
export const recupererRoadmap = async () => {
  const roadmap = [];
  try {
    const querySnapshot = await getDocs(collection(db, "roadmap"));
    querySnapshot.forEach((doc) => {
      roadmap.push({ id: doc.id, ...doc.data() });
    });
  } catch (e) {
    console.error("Erreur lors de la récupération de roadmap :", e);
  }
  return roadmap;
};

// Mettre à jour un élément de 'roadmap'
export const mettreAJourRoadmap = async (id, champsMisAJour) => {
  try {
    const docRef = doc(db, "roadmap", id);
    await updateDoc(docRef, champsMisAJour);
    console.log("Document roadmap mis à jour :", id);
  } catch (e) {
    console.error("Erreur lors de la mise à jour du document roadmap :", e);
  }
};

// Supprimer un élément de 'roadmap'
export const supprimerRoadmap = async (id) => {
  try {
    await deleteDoc(doc(db, "roadmap", id));
    console.log("Document roadmap supprimé :", id);
  } catch (e) {
    console.error("Erreur lors de la suppression du document roadmap :", e);
  }
};

// CRM Functions
export const ajouterEntrepriseCRM = async (entreprise) => {
    try {
      const docRef = await addDoc(collection(db, "crm"), entreprise);
      console.log("Document CRM ajouté avec ID :", docRef.id);
      return { id: docRef.id, ...entreprise };
    } catch (e) {
      console.error("Erreur lors de l'ajout du document CRM :", e);
      return null;
    }
  };
  
  export const recupererCRM = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "crm"));
      const crm = [];
      querySnapshot.forEach((doc) => {
        crm.push({ id: doc.id, ...doc.data() });
      });
      return crm;
    } catch (e) {
      console.error("Erreur lors de la récupération de CRM :", e);
      return [];
    }
  };
  
  export const mettreAJourCRM = async (id, champsMisAJour) => {
    try {
      const docRef = doc(db, "crm", id);
      await updateDoc(docRef, champsMisAJour);
      console.log("Document CRM mis à jour :", id);
    } catch (e) {
      console.error("Erreur lors de la mise à jour du document CRM :", e);
    }
  };
  
  export const supprimerCRM = async (id) => {
    try {
      const docRef = doc(db, "crm", id);
      await deleteDoc(docRef);
      console.log("Document CRM supprimé :", id);
    } catch (e) {
      console.error("Erreur lors de la suppression du document CRM :", e);
    }
  };